import "./index.css";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";
import Dashboard from "./Components/Navbar/Dashboard";
import Rooms from "./Pages/Rooms/Rooms";
import Booking from "./Pages/Booking/Booking";
import Addbooking from "./Pages/Addbooking/Addbooking";
import Addrooms from "./Pages/Addrooms/Addrooms";
import UpdateRoom from './Pages/UpdateRoom/UpdateRoom'
import Login from "./Pages/Login/Login";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const userLoggedIn = Cookies.get('isLoggedIn');
    setIsLoggedIn(userLoggedIn);
  }, [isLoggedIn]);
  return (
    <>
      <div className="mainbodylayout">
      {isLoggedIn && <Dashboard />}
        <Routes>
        {isLoggedIn ?<>
          <Route exact path="/" element={<Booking />} />
          <Route exact path="/addbooking" element={<Addbooking/>} />
          <Route exact path="/rooms" element={<Rooms />} />
          <Route exact path="/addrooms" element={<Addrooms />} />
          <Route exact path="/update_room" element={<UpdateRoom />} />
          <Route exact path="/login" element={<Booking/>} />
          </>:
          <>
           <Route exact path="/" element={<Login/>} />
           <Route exact path="/addbooking" element={<Login/>} />
           <Route exact path="/rooms" element={<Login/>} />
           <Route exact path="/addrooms" element={<Login />} />
           <Route exact path="/login" element={<Login />} />
           </>
}
        </Routes>   
      </div>
    </>
  );
}
export default App;
