import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchRooms,updateRoom } from '../../redux/actions/roomAction';

const UpdateRoom = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const id = query.get("id");

    const { loading, data, error } = useSelector(state => state.rooms);
    console.log(data)
    const room = data.find((room) => room.id === parseInt(id));
  
    useEffect(() => {
        if (data.length === 0) {
            dispatch(fetchRooms());
        }
    }, [dispatch, data.length]);

    const [roomData, setRoomData] = useState({
        name: '',
        price: '',
        area: '',
        single_bed: '',
        double_bed: '',
        garden_view: '',
        mountain_view: '',
        private_bathroom: '',
        flat_screen_tv: '',
        air_conditionar: '',
        break_fast: ''
    });

    useEffect(() => {
        if (room) {
            setRoomData(room);
        }
    }, [room]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoomData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("HERE IS THE ROOM DATA : ",roomData)
        dispatch(updateRoom(roomData));
        navigate('/rooms');
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    return (
        <div className="max-w-md mx-auto bg-white p-6 shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Update Room</h2>
            <form onSubmit={handleSubmit}>
                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Room Name
                    </label>
                    <div className="mt-1">
                        <input
                            id="name"
                            name="name"
                            value={roomData.name}
                            onChange={handleChange}
                            type="text"
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        />
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                        Price
                    </label>
                    <div className="mt-1">
                        <input
                            id="price"
                            name="price"
                            value={roomData.price}
                            onChange={handleChange}
                            type="text"
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        />
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="area" className="block text-sm font-medium leading-6 text-gray-900">
                        Area (sq ft)
                    </label>
                    <div className="mt-1">
                        <input
                            id="area"
                            name="area"
                            value={roomData.area}
                            onChange={handleChange}
                            type="text"
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="single_bed" className="block text-sm font-medium leading-6 text-gray-900">
                        Single Bed
                    </label>
                    <div className="mt-1">
                        <input
                            id="single_bed"
                            name="single_bed"
                            value={roomData.single_bed}
                            onChange={handleChange}
                            type="text"
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        />
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="double_bed" className="block text-sm font-medium leading-6 text-gray-900">
                        Double Bed
                    </label>
                    <div className="mt-1">
                        <input
                            id="double_bed"
                            name="double_bed"
                            value={roomData.double_bed}
                            onChange={handleChange}
                            type="text"
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        />
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="garden_view" className="block text-sm font-medium leading-6 text-gray-900">
                        Garden View
                    </label>
                    <div className="mt-1">
                        <select
                            id="garden_view"
                            name="garden_view"
                            value={roomData.garden_view}
                            onChange={handleChange}
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        >
                            <option value="0">Null</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="mountain_view" className="block text-sm font-medium leading-6 text-gray-900">
                        Mountain View
                    </label>
                    <div className="mt-1">
                        <select
                            id="mountain_view"
                            name="mountain_view"
                            value={roomData.mountain_view}
                            onChange={handleChange}
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        >
                            <option value="0">Null</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="private_bathroom" className="block text-sm font-medium leading-6 text-gray-900">
                        Private Bathroom
                    </label>
                    <div className="mt-1">
                        <select
                            id="private_bathroom"
                            name="private_bathroom"
                            value={roomData.private_bathroom}
                            onChange={handleChange}
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        >
                            <option value="0">Null</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="flat_screen_tv" className="block text-sm font-medium leading-6 text-gray-900">
                        Flat Screen TV
                    </label>
                    <div className="mt-1">
                        <select
                            id="flat_screen_tv"
                            name="flat_screen_tv"
                            value={roomData.flat_screen_tv}
                            onChange={handleChange}
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        >
                            <option value="0">Null</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="air_conditionar" className="block text-sm font-medium leading-6 text-gray-900">
                        Air Conditioner
                    </label>
                    <div className="mt-1">
                        <select
                            id="air_conditionar"
                            name="air_conditionar"
                            value={roomData.air_conditionar}
                            onChange={handleChange}
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        >
                            <option value="0">Null</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>

                <div className="sm:col-span-4 pb-3">
                    <label htmlFor="break_fast" className="block text-sm font-medium leading-6 text-gray-900">
                        Breakfast
                    </label>
                    <div className="mt-1">
                        <select
                            id="break_fast"
                            name="break_fast"
                            value={roomData.break_fast}
                            onChange={handleChange}
                            className="block w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                            required
                        >
                            <option value="0">Null</option>
                            <option value="1">Yes</option>
                        </select>
                    </div>
                </div>

                <div className="mt-4">
                    <button
                        type="submit"
                        className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
                    >
                        Update Room
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UpdateRoom;
