import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { addRoom } from "../../redux/actions/roomAction";

const Addrooms = () => {
  const dispatch = useDispatch();
  const [filesArray, setFilesArray] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    const cleanup = () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("drop", handleDrop);
    };
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("drop", handleDrop);
    return cleanup;
  });

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    setFilesArray((prevFiles) => [...prevFiles, ...files]);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setFilesArray((prevFiles) => [...prevFiles, ...files]);
  };
  const handleRemoveFile = (index) => {
    const updatedFiles = [...filesArray];
    updatedFiles.splice(index, 1);
    setFilesArray(updatedFiles);
  };
  const openFileInput = () => {
    const fileInput = document.getElementById("room_files");
    if (fileInput) {
      fileInput.click();
    }
  };

  const [roomData, setRoomData] = useState({
    name: "",
    price: "",
    area: "",
    singleBed: "",
    doubleBed: "",
    gardenView: "",
    mountainView: "",
    privateBathroom: "",
    flatScreenTV: "",
    airConditionar: "",
    breakFast: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoomData({
      ...roomData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addRoom({ ...roomData, filesArray }));
      navigate('/rooms');
    } catch (err) {
      setError('Failed to add the room. Please try again.');
      console.error('Error adding room:', err);
    }
  };
  return (
    <div className="flex items-center justify-center my-10">
      <form
        className="bg-white shadow-xl rounded p-8 mx-4"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <h1 className="text-center block text-xl font-bold leading-6 text-gray-900 mb-5">
          Add Room
        </h1>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Name
          </label>
          <div className="mt-1">
            <input
              id="name"
              name="name"
              value={roomData.name}
              onChange={handleChange}
              type="text"
              className="block pr-6 w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Enter room name"
              required
            />
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="price"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            price
          </label>
          <div className="mt-1">
            <input
              id="price"
              name="price"
              value={roomData.price}
              onChange={handleChange}
              type="text"
              className="block pr-6 w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Enter price "
              required
            />
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="area"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Area
          </label>
          <div className="mt-1">
            <input
              id="area"
              name="area"
              value={roomData.area}
              onChange={handleChange}
              type="text"
              className="block pr-6 w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Enter area in sq. meters"
            />
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="singleBed"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Single Bed
          </label>
          <div className="mt-1">
            <input
              id="singleBed"
              name="singleBed"
              value={roomData.singleBed}
              onChange={handleChange}
              type="text"
              className="block pr-6 w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Enter number of single beds"
              required
            />
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="doubleBed"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Double Bed
          </label>
          <div className="mt-1">
            <input
              id="doubleBed"
              name="doubleBed"
              value={roomData.doubleBed}
              onChange={handleChange}
              type="text"
              className="block pr-6 w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Enter number of double beds"
              required
            />
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="gardenView"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Garden View
          </label>
          <div className="mt-1">
            <select
              id="gardenView"
              name="gardenView"
              required
              value={roomData.gardenView}
              onChange={handleChange}
              className="block w-full pr-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            >
              <option value="0">Null</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="mountainView"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Mountain View
          </label>
          <div className="mt-1">
            <select
              id="mountainView"
              name="mountainView"
              required
              value={roomData.mountainView}
              onChange={handleChange}
              className="block w-full pr-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            >
              <option value="0">Null</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="privateBathroom"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Private Bathroom
          </label>
          <div className="mt-1">
            <select
              id="privateBathroom"
              name="privateBathroom"
              required
              value={roomData.privateBathroom}
              onChange={handleChange}
              className="block w-full pr-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            >
              <option value="0">Null</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="flatScreenTV"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Flat Screen TV
          </label>
          <div className="mt-1">
            <select
              id="flatScreenTV"
              name="flatScreenTV"
              required
              value={roomData.flatScreenTV}
              onChange={handleChange}
              className="block w-full pr-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            >
              <option value="0">Null</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="flatScreenTV"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Air Conditionar
          </label>
          <div className="mt-1">
            <select
              id="airConditionar"
              name="airConditionar"
              required
              value={roomData.airConditionar}
              onChange={handleChange}
              className="block w-full pr-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            >
              <option value="0">Null</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <label
            htmlFor="flatScreenTV"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Break Fast
          </label>
          <div className="mt-1">
            <select
              id="breakFast"
              name="breakFast"
              required
              value={roomData.breakFast}
              onChange={handleChange}
              className="block w-full pr-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            >
              <option value="0">Null</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4 pb-3">
          <div className="py-6">
            <div
              id="file_drop_zone"
              className="border rounded border-dashed border-gray-300 p-8 cursor-pointer mb-4"
              onClick={openFileInput}
            >
              <p className="text-gray-600 mb-2">
                Drag and drop files here or click to select files
              </p>
              <input
                type="file"
                id="room_files"
                name="room_files"
                className="hidden"
                accept="*"
                onChange={handleFileSelection}
                multiple
              />
            </div>
            
            <div id="selected_files_container" className="mb-4">
              <label
                htmlFor="selected_files"
                className="block text-gray-700 font-bold mb-2"
              >
                Selected Files:
              </label>
              <ul
                id="selected_files"
                className="border rounded border-gray-300 p-2"
              >
                {filesArray.map((file, index) => (
                  <li key={index} className="flex items-center">
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      className="ml-2 text-red-600 font-semibold focus:outline-none"
                      onClick={() => handleRemoveFile(index)}
                    >
                      ❌
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {error && <div className="text-red-600">{error}</div>}
        <div className="flex items-end justify-end mt-3">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Add Room
          </button>
        </div>
      </form>
    </div>
  );
};

export default Addrooms;
