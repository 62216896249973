import React, { useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import logo from '../../../Assets/logo/logo.png';
import Cookies from 'js-cookie';
import { animateScroll as scroll } from "react-scroll";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../redux/actions/userAction';

const SideNavbar = ({ onToggleSidebar, logout }) => {
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    onToggleSidebar();
    scroll.scrollToTop();
  };

  const handleLogout = async () => {
    try {
      Cookies.remove("token");
      Cookies.remove("isLoggedIn");
      logout();
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        console.log(error.response.data.message);
      }
    }
  };
  const handleMenuItemClick = (title) => {
    const menuItem = Menu.find((item) => item.title === title);
    if (menuItem && menuItem.onClick) {
      menuItem.onClick();
    } else {
      toggleSidebar();
    }
  };
  const Menu = [
    { title: 'Home', link: "/" },
    { title: 'Add Bookings', link: "/addbooking" },
    { title: 'Rooms', link: "/rooms" },
    { title: 'Add Rooms', link: "/addrooms" },
    { title: 'Logout', onClick: handleLogout }
  ];
  return (
    <div className={`urbanist ${sidebarVisible ? 'w-72' : 'hidden'} pb-10 bg-white h-[100vh] shadow-xl duration-300 p-5 pt-8 fixed left-0`}>
      <div className="text-white bg-white border border-dark-purple cursor-pointer text-2xl flex justify-center items-center rounded-full absolute -right-3 top-9 p-[4px]" onClick={toggleSidebar}>
        {sidebarVisible ? <MdArrowBack className="text-green-500" /> : <MdArrowForward className="text-green-500" />}
      </div>
      <div className="inline-block">
        <span className="text-4xl flex justify-center items-center rounded cursor-pointer mr-2"><img src={logo} id="logosize" alt="Chitral Green Guest House" height="60px" width="200px" /></span>
      </div>
      <ul className="mt-12 ml-3 text-black/80">
        {Menu.map((menuItem, index) => [
          menuItem.spacing && <hr key={`hr-${index}`} className="border-t border-gray-100 my-5" />,
          <Link to={menuItem.link} key={index}>
            <li
              key={index}
              className={`lightblack hover:underline ease-out transition-all duration-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2`}
              onClick={() => handleMenuItemClick(menuItem.title)}
            >
              <span className="text-2xl block float-left">{menuItem.icon}</span>
              <span className={`text-base font-medium flex-1 ${!sidebarVisible ? 'hidden' : ''}`}>{menuItem.title}</span>
            </li>
          </Link>
        ])}
      </ul>
    </div>
  );
};
const mapDispatchToProps = {
  logout
};
export default connect(null, mapDispatchToProps)(SideNavbar);
