import React, { useState } from 'react'
import SideNavbar from './Navbar/SideNavbar';
import DashboardNav from './Navbar/DashboardNav'

const Dashboard = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
 
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <DashboardNav onToggleSidebar={toggleSidebar} />
      <div className="flex">
        {sidebarVisible && (
          <div className='z-[17000] absolute top-0'>
            <SideNavbar onToggleSidebar={toggleSidebar} />
          </div>
        )}
      </div>
    </>
  )
}

export default Dashboard