// src/redux/userReducer.js
import Cookies from 'js-cookie';
import { LOGIN_SUCCESS, LOGOUT } from '../actions/userAction';

const initialState = {
  isLoggedIn: Cookies.get('isLoggedIn') === 'true',
  token: Cookies.get('token') ? { token: Cookies.get('token') } : null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      Cookies.set('token', action.payload);
      Cookies.set('isLoggedIn', true);
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload,
      };
    case LOGOUT:
      Cookies.remove('token');
      Cookies.remove('isLoggedIn');
      return {
        ...state,
        isLoggedIn: false,
        token: null,
      };
    default:
      return state;
  }
};

export default userReducer;
