import axios from 'axios';
import Cookies from 'js-cookie';

// Action Types
export const FETCH_ROOMS_REQUEST = 'FETCH_ROOMS_REQUEST';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_FAILURE = 'FETCH_ROOMS_FAILURE';
export const DELETE_ROOM_REQUEST = 'DELETE_ROOM_REQUEST';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DELETE_ROOM_FAILURE = 'DELETE_ROOM_FAILURE';
export const ADD_ROOM_REQUEST = 'ADD_ROOM_REQUEST';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_FAILURE = 'ADD_ROOM_FAILURE';
export const UPDATE_ROOM_REQUEST = 'UPDATE_ROOM_REQUEST';
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
export const UPDATE_ROOM_FAILURE = 'UPDATE_ROOM_FAILURE';
export const FETCH_BOOKINGS_REQUEST = 'FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_FAILURE = 'FETCH_BOOKINGS_FAILURE';
export const ADD_BOOKING_REQUEST = 'ADD_BOOKING_REQUEST';
export const ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS';
export const ADD_BOOKING_FAILURE = 'ADD_BOOKING_FAILURE';
export const DELETE_BOOKING_REQUEST = 'DELETE_BOOKING_REQUEST';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAILURE = 'DELETE_BOOKING_FAILURE';

// Helper function to get the token from cookies
const getAuthHeader = () => {
  const token = Cookies.get('token');
  return { Authorization: `${token}` };
};

// Fetch Rooms Actions
const fetchRoomsRequest = () => ({ type: FETCH_ROOMS_REQUEST });
const fetchRoomsSuccess = (rooms) => ({ type: FETCH_ROOMS_SUCCESS, payload: rooms });
const fetchRoomsFailure = (error) => ({ type: FETCH_ROOMS_FAILURE, payload: error });

export const fetchRooms = () => {
  return async (dispatch) => {
    dispatch(fetchRoomsRequest());
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODE_API_ENDPOINT}api/get_all_rooms`);
      dispatch(fetchRoomsSuccess(response.data.rooms));
    } catch (error) {
      dispatch(fetchRoomsFailure(error.message));
    }
  };
};

// Delete Room Actions
const deleteRoomRequest = () => ({ type: DELETE_ROOM_REQUEST });
const deleteRoomSuccess = (id) => ({ type: DELETE_ROOM_SUCCESS, payload: id });
const deleteRoomFailure = (error) => ({ type: DELETE_ROOM_FAILURE, payload: error });

export const deleteRoom = (id) => {
  return async (dispatch) => {
    dispatch(deleteRoomRequest());
    try {
      await axios.post(`${process.env.REACT_APP_NODE_API_ENDPOINT}api/del_room/${id}`, {}, { 
        withCredentials: true,
        headers: getAuthHeader()
      });
      dispatch(deleteRoomSuccess(id));
    } catch (error) {
      dispatch(deleteRoomFailure(error.message));
    }
  };
};

// Add Room Actions
const addRoomRequest = () => ({ type: ADD_ROOM_REQUEST });
const addRoomSuccess = (room) => ({ type: ADD_ROOM_SUCCESS, payload: room });
const addRoomFailure = (error) => ({ type: ADD_ROOM_FAILURE, payload: error });

export const addRoom = (roomData) => {
  return async (dispatch) => {
    dispatch(addRoomRequest());
    try {
      const formData = new FormData();
      for (const key in roomData) {
        formData.append(key, roomData[key]);
      }
      roomData.filesArray.forEach((file) => {
        formData.append("room_files", file);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API_ENDPOINT}api/add_room`,
        formData,
        { 
          withCredentials: true,
          headers: getAuthHeader()
        }
      );
      dispatch(addRoomSuccess(response.data.room));
    } catch (error) {
      dispatch(addRoomFailure(error.message));
    }
  };
};

//  Update Room Actions
const updateRoomRequest = () => ({ type: UPDATE_ROOM_REQUEST });
const updateRoomSuccess = (room) => ({ type: UPDATE_ROOM_SUCCESS, payload: room });
const updateRoomFailure = (error) => ({ type: UPDATE_ROOM_FAILURE, payload: error });

export const updateRoom = (roomData) => {
  return async (dispatch) => {
    dispatch(updateRoomRequest());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API_ENDPOINT}api/update_room`,
        roomData,
        { 
          withCredentials: true,
          headers: getAuthHeader()
        }
      );
      dispatch(updateRoomSuccess(response.data.room));
    } catch (error) {
      dispatch(updateRoomFailure(error.message));
    }
  };
};

// Fetch Bookings Actions
const fetchBookingsRequest = () => ({ type: FETCH_BOOKINGS_REQUEST });
const fetchBookingsSuccess = (bookings) => ({ type: FETCH_BOOKINGS_SUCCESS, payload: bookings });
const fetchBookingsFailure = (error) => ({ type: FETCH_BOOKINGS_FAILURE, payload: error });

export const fetchBookings = () => {
  return async (dispatch) => {
    dispatch(fetchBookingsRequest());
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODE_API_ENDPOINT}api/get_all_room_bookings`, {}, {
        withCredentials: true,
        headers: getAuthHeader()
      });
      dispatch(fetchBookingsSuccess(response.data.bookings));
    } catch (error) {
      dispatch(fetchBookingsFailure(error.message));
    }
  };
};

// Add Booking Actions
const addBookingRequest = () => ({ type: ADD_BOOKING_REQUEST });
const addBookingSuccess = (booking) => ({ type: ADD_BOOKING_SUCCESS, payload: booking });
const addBookingFailure = (error) => ({ type: ADD_BOOKING_FAILURE, payload: error });

export const addBooking = (bookingData) => {
  return async (dispatch) => {
    dispatch(addBookingRequest());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API_ENDPOINT}api/add_booking`,
        bookingData,
        { 
          withCredentials: true,
          headers: getAuthHeader()
        }
      );
      dispatch(addBookingSuccess(response.data.booking));
    } catch (error) {
      dispatch(addBookingFailure(error.message));
    }
  };
};

// Delete Booking Actions
const deleteBookingRequest = () => ({ type: DELETE_BOOKING_REQUEST });
const deleteBookingSuccess = (id) => ({ type: DELETE_BOOKING_SUCCESS, payload: id });
const deleteBookingFailure = (error) => ({ type: DELETE_BOOKING_FAILURE, payload: error });

export const deleteBooking = (id) => {
  return async (dispatch) => {
    dispatch(deleteBookingRequest());
    try {
      await axios.post(`${process.env.REACT_APP_NODE_API_ENDPOINT}api/del_booking/${id}`, {}, { 
        withCredentials: true,
        headers: getAuthHeader()
      });
      dispatch(deleteBookingSuccess(id));
    } catch (error) {
      dispatch(deleteBookingFailure(error.message));
    }
  };
};
