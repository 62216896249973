import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchRooms, deleteRoom } from '../../redux/actions/roomAction';

const DashboardHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data, error } = useSelector(state => state.rooms);

  useEffect(() => {
    dispatch(fetchRooms());
  }, [dispatch]);

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this room?');
    if (confirmDelete) {
      dispatch(deleteRoom(id));
      navigate('/rooms');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="text-center bg-[#f7f7f7] h-[100vh]">
      <div className="pt-12 m-4">
        {data.length === 0 ? (
          <div className="text-xl font-bold text-gray-600">There is no room</div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-[1800px] bg-white border-3 border-gray-300">
              <thead>
                <tr>
                  <th className="py-4 px-4 border-b w-[4rem]">#</th>
                  <th className="py-4 px-4 border-b w-[12rem]">Image</th>
                  <th className="py-4 px-4 border-b w-[15rem]">Title</th>
                  <th className="py-4 px-4 border-b w-[8rem]">Price</th>
                  <th className="py-4 px-4 border-b w-[13rem]">Area</th>
                  <th className="py-4 px-4 border-b w-[15rem]">Single Bed</th>
                  <th className="py-4 px-4 border-b w-[16rem]">Double Bed</th>
                  <th className="py-4 px-4 border-b w-[17rem]">Garden View</th>
                  <th className="py-4 px-4 border-b w-[17rem]">Mountain View</th>
                  <th className="py-4 px-4 border-b w-[19rem]">Private Bathroom</th>
                  <th className="py-4 px-4 border-b w-[18rem]">Flat Screen TV</th>
                  <th className="py-4 px-4 border-b w-[18rem]">Air Conditioner</th>
                  <th className="py-4 px-4 border-b w-[20rem]">Break Fast</th>
                  <th className="py-4 px-4 border-b w-[10rem]">Action</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {data.map((row, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                    <td className="py-4 px-4 border-b">{index + 1}</td>
                    <td className="py-4 px-4 border-b">
                      <img
                        src={`${process.env.REACT_APP_NODE_API_ENDPOINT}uploads/${row.room_files?.[0]}`}
                        alt={row.name}
                        className="w-16 h-16 object-cover rounded mx-auto"
                      />
                    </td>
                    <td className="py-4 px-4 border-b">{row.name}</td>
                    <td className="py-4 px-4 border-b">{row.price}</td>
                    <td className="py-4 px-4 border-b">{row.area}</td>
                    <td className="py-4 px-4 border-b">{row.single_bed}</td>
                    <td className="py-4 px-4 border-b">{row.double_bed}</td>
                    <td className="py-4 px-4 border-b">{row.garden_view}</td>
                    <td className="py-4 px-4 border-b">{row.mountain_view}</td>
                    <td className="py-4 px-4 border-b">{row.private_bathroom}</td>
                    <td className="py-4 px-4 border-b">{row.flat_screen_tv}</td>
                    <td className="py-4 px-4 border-b">{row.air_conditionar}</td>
                    <td className="py-4 px-4 border-b">{row.break_fast}</td>
                    <td className="py-4 px-4 border-b flex flex-col">
                      <Link to={`/update_room?id=${row.id}`}>
                        <button
                          className="bg-green-500 text-white px-3 mb-1 py-1 mr-2 rounded-md hover:bg-red-600 transition duration-300"
                        >
                          Update
                        </button>
                      </Link>
                      <button
                        onClick={() => handleDelete(row.id)}
                        className="bg-red-500 text-white px-3 mb-1 py-1 mr-2 rounded-md hover:bg-red-600 transition duration-300"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHome;
