// src/redux/rootReducer.js
import { combineReducers } from 'redux';
import userReducer from './reducer/userReducer';
import roomsReducer from './reducer/roomReducer';

const rootReducer = combineReducers({
  user: userReducer,
  rooms: roomsReducer,
});

export default rootReducer;
