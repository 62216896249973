import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchBookings, deleteBooking } from '../../redux/actions/roomAction';

const Booking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, bookings, error } = useSelector(state => state.rooms);

  useEffect(() => {
    dispatch(fetchBookings());
  }, [dispatch]);

  const handleDelete = (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this booking?');
    if (confirmDelete) {
      dispatch(deleteBooking(id));
    }
  };

  const handleAddBooking = () => {
    navigate('/addbooking');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="text-center bg-[#f7f7f7] min-h-screen">
      <div className="pt-12 m-4">
        {bookings.length === 0 ? (
          <div className="text-xl font-bold text-gray-600">There are no bookings</div>
        ) : (
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Bookings</h2>
              <button
                onClick={handleAddBooking}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
              >
                Add Booking
              </button>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full bg-white border border-gray-300">
                <thead>
                  <tr>
                    <th className="py-4 px-4 border-b w-16">#</th>
                    <th className="py-4 px-4 border-b w-48">Room Name</th>
                    <th className="py-4 px-4 border-b w-60">Booking Date</th>
                    <th className="py-4 px-4 border-b w-40">Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {bookings.map((booking, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                      <td className="py-4 px-4 border-b">{index + 1}</td>
                      <td className="py-4 px-4 border-b">
                        {booking?.room_name || 'Room name not available'}
                      </td>
                      <td className="py-4 px-4 border-b">
                        {booking?.date ? new Date(booking.date).toLocaleDateString() : 'Date not available'}
                      </td>
                      <td className="py-4 px-4 border-b">
                        <button
                          onClick={() => handleDelete(booking?.id)}
                          className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 transition duration-300"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Booking;
