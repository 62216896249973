import {
  FETCH_ROOMS_REQUEST,
  FETCH_ROOMS_SUCCESS,
  FETCH_ROOMS_FAILURE,
  DELETE_ROOM_REQUEST,
  DELETE_ROOM_SUCCESS,
  DELETE_ROOM_FAILURE,
  ADD_ROOM_REQUEST,
  ADD_ROOM_SUCCESS,
  ADD_ROOM_FAILURE,
  UPDATE_ROOM_REQUEST,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_FAILURE,
  FETCH_BOOKINGS_REQUEST,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILURE,
  ADD_BOOKING_REQUEST,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAILURE,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAILURE,
} from '../actions/roomAction';

const initialState = {
  loading: false,
  data: [],
  bookings: [],
  error: null,
};

const roomsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Rooms Actions
    case FETCH_ROOMS_REQUEST:
    case DELETE_ROOM_REQUEST:
    case ADD_ROOM_REQUEST:
    case UPDATE_ROOM_REQUEST:
    case FETCH_BOOKINGS_REQUEST:
    case ADD_BOOKING_REQUEST:
    case DELETE_BOOKING_REQUEST:
      return { ...state, loading: true };

    case FETCH_ROOMS_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case FETCH_ROOMS_FAILURE:
    case DELETE_ROOM_FAILURE:
    case ADD_ROOM_FAILURE:
    case FETCH_BOOKINGS_FAILURE:
    case ADD_BOOKING_FAILURE:
    case UPDATE_ROOM_FAILURE:
    case DELETE_BOOKING_FAILURE:  // Handle error state for deleting booking
      return { ...state, loading: false, error: action.payload };

    case DELETE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(room => room.id !== action.payload),
      };

    case ADD_ROOM_SUCCESS:
    case UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      };

    // Bookings Actions
    case FETCH_BOOKINGS_SUCCESS:
      return { ...state, loading: false, bookings: action.payload };

    case ADD_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: [...state.bookings, action.payload],
      };

    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: state.bookings.filter(booking => booking.id !== action.payload),
      };

    default:
      return state;
  }
};

export default roomsReducer;
