import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchRooms, addBooking } from "../../redux/actions/roomAction";
import { useNavigate } from 'react-router-dom';

const Addbooking = () => {
  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.rooms.data || []);
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState({
    roomId: "",
    roomName: "",
    bookingDateStart: "",
    bookingDateEnd: "",
  });
  const [error, setError] = useState("");

  useEffect(() => { 
      dispatch(fetchRooms());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update both roomId and roomName when a room is selected
    if (name === "roomId") {
      const selectedRoom = rooms.find(room => room.id === parseInt(value));
      setBookingData({
        ...bookingData,
        roomId: value,
        roomName: selectedRoom ? selectedRoom.name : "",
      });
    } else {
      setBookingData({
        ...bookingData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addBooking(bookingData));
      navigate('/');
    } catch (error) {
      setError("Failed to book room. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center my-10">
      <form className="bg-white shadow-xl rounded py-8 px-8 min-w-[90%] md:min-w-[400px] mx-4" onSubmit={handleSubmit}>
        <h1 className="text-center block text-xl font-bold leading-6 text-gray-900 mb-5">Book a Room</h1>

        <div className="pb-3">
          <label htmlFor="roomId" className="block text-sm font-medium leading-6 text-gray-900">
            Room ID
          </label>
          <div className="mt-1">
            <select
              id="roomId"
              name="roomId"
              value={bookingData.roomId}
              onChange={handleChange}
              className="block w-full pr-6 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              required
            >
              <option value="">Select a room</option>
              {rooms.length > 0 ? (
                rooms.map((room) => (
                  <option key={room.id} value={room.id}>
                    {room.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>No rooms available</option>
              )}
            </select>
          </div>
        </div>

        <div className="pb-3">
          <label htmlFor="bookingDateStart" className="block text-sm font-medium leading-6 text-gray-900">
            Booking Date Start
          </label>
          <div className="mt-1">
            <input
              id="bookingDateStart"
              name="bookingDateStart"
              value={bookingData.bookingDateStart}
              onChange={handleChange}
              type="date"
              className="block pr-6 w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              required
            />
          </div>
        </div>
        <div className="pb-3">
          <label htmlFor="bookingDateEnd" className="block text-sm font-medium leading-6 text-gray-900">
            Booking Date End
          </label>
          <div className="mt-1">
            <input
              id="bookingDateEnd"
              name="bookingDateEnd"
              value={bookingData.bookingDateEnd}
              onChange={handleChange}
              type="date"
              className="block pr-6 w-full rounded-md pl-1.5 outline-none border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              required
            />
          </div>
        </div>

        {error && <div className="text-red-600">{error}</div>}
        <div className="flex items-end justify-end mt-3">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Book Room
          </button>
        </div>
      </form>
    </div>
  );
};

export default Addbooking;
